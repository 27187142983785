@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BasierSquare';
  src: local('BasierSquare'), url(./assets/fonts/Basier_Square_Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'ArialRounded';
  src: local('ArialRounded'), url(./assets/fonts/Arial_Rounded_MT_Bold.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@layer components {
  .btn-square {
    @apply bg-sun_yellow cursor-pointer mt-8 mb-10 h-10 text-xl rounded-lg hover:shadow-lg sm:w-[30%];
  }
}

body {
  margin: 0;
  font-family: 'BasierSquare';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
